/* based on angular-toastr css https://github.com/Foxandxss/angular-toastr/blob/cb508fe6801d6b288d3afc525bb40fee1b101650/dist/angular-toastr.css */
/* position */
.splash {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100vh;

  .splash-gif {
    height: 75px;
    width: 75px;
  }

  .splash-header {
    margin-top: 20px;
    width: 250px;
  }
}

.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}
.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}
.toast-top-left {
  top: 12px;
  left: 12px;
}
.toast-top-right {
  top: 12px;
  right: 12px;
}
.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}
.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}
.toast-message {
  word-wrap: break-word;
}
.toast-message a,
.toast-message label {
  color: #ffffff;
}
.toast-message a:hover {
  color: #cccccc;
  text-decoration: none;
}
.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}
.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}
/*Additional properties for button version
   iOS requires the button element instead of an anchor tag.
   If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}
.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}
.toast-container * {
  box-sizing: border-box;
}
.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #ffffff;
}
.toast-container .ngx-toastr:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  cursor: pointer;
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOS4wNDMgOCA4IDExOS4wODMgOCAyNTZjMCAxMzYuOTk3IDExMS4wNDMgMjQ4IDI0OCAyNDhzMjQ4LTExMS4wMDMgMjQ4LTI0OEM1MDQgMTE5LjA4MyAzOTIuOTU3IDggMjU2IDh6bTAgMTEwYzIzLjE5NiAwIDQyIDE4LjgwNCA0MiA0MnMtMTguODA0IDQyLTQyIDQyLTQyLTE4LjgwNC00Mi00MiAxOC44MDQtNDIgNDItNDJ6bTU2IDI1NGMwIDYuNjI3LTUuMzczIDEyLTEyIDEyaC04OGMtNi42MjcgMC0xMi01LjM3My0xMi0xMnYtMjRjMC02LjYyNyA1LjM3My0xMiAxMi0xMmgxMnYtNjRoLTEyYy02LjYyNyAwLTEyLTUuMzczLTEyLTEydi0yNGMwLTYuNjI3IDUuMzczLTEyIDEyLTEyaDY0YzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MTAwaDEyYzYuNjI3IDAgMTIgNS4zNzMgMTIgMTJ2MjR6Jy8+PC9zdmc+");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTI1NiA4QzExOSA4IDggMTE5IDggMjU2czExMSAyNDggMjQ4IDI0OCAyNDgtMTExIDI0OC0yNDhTMzkzIDggMjU2IDh6bTEyMS42IDMxMy4xYzQuNyA0LjcgNC43IDEyLjMgMCAxN0wzMzggMzc3LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwyNTYgMzEybC02NS4xIDY1LjZjLTQuNyA0LjctMTIuMyA0LjctMTcgMEwxMzQuNCAzMzhjLTQuNy00LjctNC43LTEyLjMgMC0xN2w2NS42LTY1LTY1LjYtNjUuMWMtNC43LTQuNy00LjctMTIuMyAwLTE3bDM5LjYtMzkuNmM0LjctNC43IDEyLjMtNC43IDE3IDBsNjUgNjUuNyA2NS4xLTY1LjZjNC43LTQuNyAxMi4zLTQuNyAxNyAwbDM5LjYgMzkuNmM0LjcgNC43IDQuNyAxMi4zIDAgMTdMMzEyIDI1Nmw2NS42IDY1LjF6Jy8+PC9zdmc+");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1MTIgNTEyJyB3aWR0aD0nNTEyJyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTE3My44OTggNDM5LjQwNGwtMTY2LjQtMTY2LjRjLTkuOTk3LTkuOTk3LTkuOTk3LTI2LjIwNiAwLTM2LjIwNGwzNi4yMDMtMzYuMjA0YzkuOTk3LTkuOTk4IDI2LjIwNy05Ljk5OCAzNi4yMDQgMEwxOTIgMzEyLjY5IDQzMi4wOTUgNzIuNTk2YzkuOTk3LTkuOTk3IDI2LjIwNy05Ljk5NyAzNi4yMDQgMGwzNi4yMDMgMzYuMjA0YzkuOTk3IDkuOTk3IDkuOTk3IDI2LjIwNiAwIDM2LjIwNGwtMjk0LjQgMjk0LjQwMWMtOS45OTggOS45OTctMjYuMjA3IDkuOTk3LTM2LjIwNC0uMDAxeicvPjwvc3ZnPg==");
}
/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHZpZXdCb3g9JzAgMCA1NzYgNTEyJyB3aWR0aD0nNTc2JyBoZWlnaHQ9JzUxMic+PHBhdGggZmlsbD0ncmdiKDI1NSwyNTUsMjU1KScgZD0nTTU2OS41MTcgNDQwLjAxM0M1ODcuOTc1IDQ3Mi4wMDcgNTY0LjgwNiA1MTIgNTI3Ljk0IDUxMkg0OC4wNTRjLTM2LjkzNyAwLTU5Ljk5OS00MC4wNTUtNDEuNTc3LTcxLjk4N0wyNDYuNDIzIDIzLjk4NWMxOC40NjctMzIuMDA5IDY0LjcyLTMxLjk1MSA4My4xNTQgMGwyMzkuOTQgNDE2LjAyOHpNMjg4IDM1NGMtMjUuNDA1IDAtNDYgMjAuNTk1LTQ2IDQ2czIwLjU5NSA0NiA0NiA0NiA0Ni0yMC41OTUgNDYtNDYtMjAuNTk1LTQ2LTQ2LTQ2em0tNDMuNjczLTE2NS4zNDZsNy40MTggMTM2Yy4zNDcgNi4zNjQgNS42MDkgMTEuMzQ2IDExLjk4MiAxMS4zNDZoNDguNTQ2YzYuMzczIDAgMTEuNjM1LTQuOTgyIDExLjk4Mi0xMS4zNDZsNy40MTgtMTM2Yy4zNzUtNi44NzQtNS4wOTgtMTIuNjU0LTExLjk4Mi0xMi42NTRoLTYzLjM4M2MtNi44ODQgMC0xMi4zNTYgNS43OC0xMS45ODEgMTIuNjU0eicvPjwvc3ZnPg==");
}
.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}
.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}
.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}
.toast-success {
  background-color: #51a351;
}
.toast-error {
  background-color: #bd362f;
}
.toast-info {
  background-color: #2f96b4;
}
.toast-warning {
  background-color: #f89406;
}
.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}
/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}

.order-confirm-icon {
  margin-bottom: 15px;
  font-size: 80px;
}

.success {
  color: var(--bs-teal);
}

.error {
  color: var(--bs-red);
}

.date-row {
  width: 157.016px;
}

.email-row {
  width: 250px;
}

.status-column {
  text-align: center;
}

.table > :not(caption) > * > * {
  padding: 1.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.order-action {
  font-size: 23px;
}

.id-row {
  width: 54px;
}

.prod-col {
  margin: 10px;
}

.feature-box .icon {
  font-size: 30px;
  position: absolute;
  left: 15px;
  top: 15px;
  width: 30px;
  text-align: center;
  color: var(--bs-gray-800);
}

.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.spinner-large {
  font-size: 16px;
  height: 50px;
  width: 50px;
}

.bs-icon {
  --bs-icon-size: 0.75rem;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: var(--bs-icon-size);
  width: calc(var(--bs-icon-size) * 2);
  height: calc(var(--bs-icon-size) * 2);
  color: var(--bs-primary);
}

.bs-icon {
  --bs-icon-size: 0.75rem;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: var(--bs-icon-size);
  width: calc(var(--bs-icon-size) * 2);
  height: calc(var(--bs-icon-size) * 2);
  color: var(--bs-primary);
}

.bs-icon-xs {
  --bs-icon-size: 1rem;
  width: calc(var(--bs-icon-size) * 1.5);
  height: calc(var(--bs-icon-size) * 1.5);
}

.bs-icon-sm {
  --bs-icon-size: 1rem;
}

.bs-icon-md {
  --bs-icon-size: 1.5rem;
}

.bs-icon-lg {
  --bs-icon-size: 2rem;
}

.bs-icon-xl {
  --bs-icon-size: 2.5rem;
}

.bs-icon.bs-icon-primary {
  color: var(--bs-white);
  background: var(--bs-primary);
}

.bs-icon.bs-icon-primary-light {
  color: var(--bs-body-bg);
  background: var(--bs-gray-dark);
}

.bs-icon.bs-icon-semi-white {
  color: var(--bs-primary);
  background: rgba(255, 255, 255, 0.5);
}

.bs-icon.bs-icon-rounded {
  border-radius: 0.5rem;
}

.bs-icon.bs-icon-circle {
  border-radius: 50%;
}

main {
  background-color: #f6f6f6;
  min-height: 90%;
}

.detail-card {
  padding: 70px;
}

.product-detail-thumbnail {
  width: 90%;
}

.order-detail-group {
  padding: 20px;
}

.status-selector {
  padding: 10px;
  --bs-body-font-size: 1rem;
  font-size: 18px;
}

.muted-warning-text {
  color: var(--bs-gray);
}

.fas.fa-times.remove-lineitem-icon {
  margin-left: 15px;
  font-size: 25px;
}

.product-summary-img {
  width: 100%;
  max-width: 150px;
}

#pay-button {
  margin-bottom: 25px;
}

.not-found-icon {
  margin-bottom: 15px;
  font-size: 80px;
  color: var(--bs-danger);
}

.product-row img {
  width: 50px;
  height: 49px;
}

.product-headers th {
  text-align: center;
}

.add-button {
  height: 50px;
  margin-left: 20px;
  align-items: center;
  justify-content: center;
  display: flex !important;
}

.table-head {
  width: 100%;
  display: flex;
}

.category-add {
  margin-right: 15px;
}

.input-group, .content-input-group {
  margin-top: 15px;
  margin-right: 15px;
  margin-bottom: 15px;
}

.input-group-text-input {
  border: 1px solid #ced4da;
}

.input-group-text-input:disabled,
.input-group-text-input[readonly] {
  background-color: #e9ecef;
}

.input-group-select, .input-group-text-input {
  border-radius: 6px !important;
  padding: 14px 12px !important;
}

.text-area {
  height: 100px;
}

.image-add-button {
  margin-bottom: 10px;
}

.product-carousel {
  width: 400px;
  max-width: 100%;
}

#additional-carousel-1 {
  width: 700px;
}

.product-action-buttons button {
  margin-left: 20px;
}

.product-header button {
  margin-left: 15px;
}

.half-field {
  width: 50%;
}

.toggle {
  width: 30px;
  height: 30px;
  margin-left: 12px !important;
}

.order-confirm-icon {
  margin-bottom: 15px;
  font-size: 80px;
}

.storefront-lock {
  width: 50%;
}

.block-heading {
  text-align: center;
}

.closed-icon {
  margin-bottom: 15px;
  font-size: 80px;
  color: var(--bs-warning);
}

.editable:hover {
  background-image: url("https://storage.googleapis.com/cypress-online-static/edit-section.png") !important;
  background-size: 3% !important;
  background-position: 95% 1%;
  background-repeat: no-repeat;
  border-radius: 4px;
  border-color: gray;
  border-style: solid;
  cursor: pointer;
  opacity: 0.85;
  transition: opacity 0.75s;

  .reorder {
    display: block;
    opacity: 1;
  }
}

.modal-content {
  width: 850px !important;
}

.modal-dialog {
  display: flex !important;
  justify-content: center !important;
  max-width: 1000px !important;
}

/* FIGMA DESIGNS */
thead {
  padding: 16px 24px;
  color: white;
  background: #222739;
  th:first-child {
    border-radius: 8px 0px 0px 0px !important;
  }
  th:last-child {
    border-radius: 0px 8px 0px 0px !important;
  }
}

.search {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px 16px 16px 16px;
  gap: 22px;
  width: 400px;
  height: 56px;
  background: #ffffff;
  border: 0.6px solid #a8acb8;
  border-radius: 100px !important;
  flex: none;
  order: 0;
  flex-grow: 0;
}

.btn-primary {
  color: white !important;
  background-color: #ef4136 !important;
  border-color: transparent !important;
  border-radius: 8px !important;
}

.btn-outline {
  color: #08081B !important;
  background-color: #FFFFFF !important;
  border: 1px solid #08081B !important;
  border-radius: 8px !important;
}

.btn-action {
  padding: 8px 20px !important;
  min-width: 115px !important;
}

.btn-100 {
  width: 100% !important;
}

/*====================== SWITCH =======================*/
/* The switch - the box around the slider */
.switch-container {
  label {
    padding-bottom: 5px;
  }
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ef4136;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ef4136;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

input:disabled + .slider {
  opacity: 0.4;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.switch-form-label {
  display: flex;
  align-items: center;
  margin-left: 15px !important;
}

.two-field {
  flex-direction: column;
}

.two-field div:last-child, .one-field div:last-child {
  margin-right: 0 !important;
}

.info-banner > span, .info-banner > div {
  background-color: #393949;
  color: #FFFFFF;
  padding: 10px 16px;
  display: inline-block;
  font-size: 12px;
  border-radius: 8px;
  margin-top: 15px;
}

.info-banner-content {
  display: flex;
  align-items: center;
}

.info-banner-text {
  padding: 0 0 0 10px;
}

.no-border {
  border: 0;
  all: unset;
}

.border-50 {
  border-radius: 50px !important;
}

.search-box {
  border: 1px solid #A8ACB8;
  padding: 14px 20px !important;
  width: 400px;
}

.search-box-select {
  background-color: #393949;
  border-radius: 10px;
  padding: 0 20px !important;
  margin: 0 10px;
  width: 100%;

  select {
    background-color: transparent;
    border: none;
    color: #FFFFFF;
    cursor: pointer;
    width: 100%;
    outline: none;
    padding: 15px 0;
    font-size: 16px;
  }

  option {
    color: #000000;
  }
}

.bottom-action-bar {
  background-color: #FFFFFF;
  position: sticky;
  position: -webkit-sticky;
  bottom: 0;
  left: 0;
  right: 0;
  height: 70px;
  box-shadow: 0px 5px 20px rgba(12, 12, 26, 0.15);

  display: flex;
  justify-content: center;
  align-items: center;
}

.bottom-action-bar-content {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.nav-tabs .nav-item {
  margin-right: 30px;
}

.nav-tabs .nav-item:last-child {
  margin-right: 0;
}

@media (min-width: 576px) {

}
@media (min-width: 768px) {
  .two-field {
    flex-direction: row;
  }
}
@media (min-width: 992px) {

}
@media (min-width: 1200px) {
  .banner-content {
    max-width: 1140px;
  }
  .bottom-action-bar-content {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .banner-content {
    max-width: 1320px;
  }
  .bottom-action-bar-content {
    max-width: 1320px;
  }
}