.order-confirm-icon {
  margin-bottom: 15px;
  font-size: 80px;
}

.success {
  color: var(--bs-teal);
}

.error {
  color: var(--bs-red);
}

.date-row {
  width: 157.016px;
}

.email-row {
  width: 250px;
}

.status-column {
  text-align: center;
}

td {
}

.table > :not(caption) > * > * {
  padding: 1.5rem .5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}

.order-action {
  font-size: 23px;
}

.id-row {
  width: 54px;
}

@media (min-width: 992px) {
  img {
  }
}

.page-footer.dark {
}

.prod-col {
  margin: 10px;
}

.feature-box .icon {
  font-size: 30px;
  position: absolute;
  left: 15px;
  top: 15px;
  width: 30px;
  text-align: center;
  color: var(--bs-gray-800);
}

.prod-qty {
}

.input-group > .form-control, .input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}

.product-option-select {
}

.product-option-label {
}

.product-option-field {
}

.spinner-large {
  font-size: 16px;
  height: 50px;
  width: 50px;
}

#product-detail {
}

.bs-icon {
  --bs-icon-size: .75rem;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: var(--bs-icon-size);
  width: calc(var(--bs-icon-size) * 2);
  height: calc(var(--bs-icon-size) * 2);
  color: var(--bs-primary);
}

.bs-icon {
  --bs-icon-size: .75rem;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: var(--bs-icon-size);
  width: calc(var(--bs-icon-size) * 2);
  height: calc(var(--bs-icon-size) * 2);
  color: var(--bs-primary);
}

.bs-icon-xs {
  --bs-icon-size: 1rem;
  width: calc(var(--bs-icon-size) * 1.5);
  height: calc(var(--bs-icon-size) * 1.5);
}

.bs-icon-sm {
  --bs-icon-size: 1rem;
}

.bs-icon-md {
  --bs-icon-size: 1.5rem;
}

.bs-icon-lg {
  --bs-icon-size: 2rem;
}

.bs-icon-xl {
  --bs-icon-size: 2.5rem;
}

.bs-icon.bs-icon-primary {
  color: var(--bs-white);
  background: var(--bs-primary);
}

.bs-icon.bs-icon-primary-light {
  color: var(--bs-body-bg);
  background: var(--bs-gray-dark);
}

.bs-icon.bs-icon-semi-white {
  color: var(--bs-primary);
  background: rgba(255, 255, 255, .5);
}

.bs-icon.bs-icon-rounded {
  border-radius: .5rem;
}

.bs-icon.bs-icon-circle {
  border-radius: 50%;
}

main {
  background-color: #f6f6f6;
  min-height: 90%;
}

.detail-card {
  padding: 70px;
}

.product-detail-thumbnail {
  width: 90%;
}

.order-detail-group {
  padding: 20px;
}

.status-selector {
  padding: 10px;
  --bs-body-font-size: 1rem;
  font-size: 18px;
}

.muted-warning-text {
  color: var(--bs-gray);
}

.quantity-counter {
}

.quantity-input {
}

.fas.fa-times.remove-lineitem-icon {
  margin-left: 15px;
  font-size: 25px;
}

.product-summary-img {
  width: 100%;
  max-width: 150px;
}

#pay-button {
  margin-bottom: 25px;
}

